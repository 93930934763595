<template>
	<div class="tf-create-item tf-section">
		<div class="themesflat-container" style="margin-top: 100px;">
			<div class="row">
				<div class="col-xl-3 col-lg-4 col-md-6 col-12">
					<h4 class="title-create-item">Collection info to upload NFTs</h4>
				</div>
				<div class="col-xl-9 col-lg-8 col-md-12 col-12">
					<div class="form-upload-profile">
						<form class="form-profile" id="collection_form" @submit="form_verification">
							<div class="form-infor-profile">
								<div class="info-account">
										<fieldset>
											<h4 class="title-infor-account">Collection ID</h4>
											<input type="text" name="collection_id" v-model="form.collection_id">
										</fieldset> 
										<fieldset>
											<h4 class="title-infor-account">Update Authority</h4>
											<input type="text" name="update_authority" v-model="form.update_authority">
										</fieldset> 
								</div>
								<div class="info-social">
										<fieldset>
											<h4 class="title-infor-account">Symbole</h4>
											<input type="text" name="symbole" v-model="form.symbole">
										</fieldset> 
								</div> 
							</div>
							<button class="tf-button-submit mg-t-15" type="submit">
								Load NFTs !
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'

import axios from 'axios';

import toastr from 'toastr'
import {getWalletAddress} from '@/libs/Wallet.js'

import {
  getNftOwner,
  getNftOwnerAccount,
  getNftOwned,
  getNftByProgram,
  getNftTokenAccountOwners,
  getOwnersFromTokenAccounts,
} from '@/libs/solanaConnection.js';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export default {
  name: 'CollectionEditNft',
  data: function () {
	
	return {
		
		url_data: this.$route.params.id,
		my_nft: {},
		form: {
			collection_id: '',
			symbole: '',
			update_authority: '',
			nfts: {},
    },
    user: {},
	}
  },
  props: {
   
  },
  methods: {
	
	form_verification : function (e) {

		e.preventDefault();

		var component_vue = this;
		
		var collection_id = this.form.collection_id;
		
		getNftByProgram(component_vue.form.update_authority, component_vue.form.symbole).then(function(liste_mint) {

			component_vue.form.nfts = JSON.stringify(liste_mint);
			
			// We put the JSON on collection.json
			axios.post(process.env.VUE_APP_ROOT_API+'/maintenance/update_json_file/'+collection_id,JSON.stringify(liste_mint), config_axios).then(function(insert_return) {

        if(insert_return.data !== false)
			toastr.success('JSON created succesfully !')

        else
			toastr.error('Error during JSON creation ...')
      });
		});
	},

  },
  created: function() {
  
		// get my_nft
		var $this = this;

		var component_vue = this;

    if(getWalletAddress() == false) {
      component_vue.$root.$emit('show_modal',['connect_wallet',true]);

      component_vue.$root.$on('connection_wallet_accept',async () => {

        axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/user/' + getWalletAddress(), config_axios).then(function(user) {

          component_vue.user = user.data.user;
          component_vue.$forceUpdate();

        });
      });


    }
    else{

      axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/user/' + getWalletAddress(), config_axios).then(function(user) {

        component_vue.user = user.data.user;
        component_vue.$forceUpdate();

      });
    }
	
  },
}
</script>

